// eslint-disable-next-line no-redeclare,no-unused-vars
function convert(param) {
  var value = param.value;
  var row = param.row;
  var key = param.key;
  var object = param.object;
  var settings = param.settings;
  if (!settings && object && key) settings = fields[object][key];
  if (!settings) return value;
  var read = true;
  if (param.input) read = false;

  if (
    key == 'short' &&
    row.id &&
    row.id != 'new' &&
    param.object &&
    !param.objectSub
  ) {
    param.id = row.id;
    checkObjectSub2(param);
    if (param.objectSub && row['short' + ucfirst(param.objectSub)])
      value = row['short' + ucfirst(param.objectSub)];
  } else if (param.table && value && key == 'file') {
    if (row.type == 'image') value = '<img src="' + value + '">';
    // else if (row.type == 'pdf' && mobile != 'smartphone') value = '<object data="' + value + '" type="application/pdf"></object>';
    else if (row.type == 'pdf')
      value = '<canvas class="pdf" src="' + value + '"></canvas>';
    else value = '<i class="icon fa-regular fa-file-download fa-fw">';
  } else if (
    settings.reference &&
    value &&
    isArray(value) &&
    !settings.refTable &&
    !settings.refValue &&
    !param.sum
  ) {
    if (value.length < 5 && (settings.convertTable || str_contains(key, '.'))) {
      var values = [];
      $.each(value, function (i, val) {
        values.push(convert(Object.assign({}, param, {value: val})));
      });
      value = values.join(', ');
      if (str_contains(value, '<')) value = value.replace(', ', '');
    } else if (isArray(value)) {
      value = value.length;
      if (!value) value = '';
    }
  } else if (
    settings.reference &&
    isNumeric(value) &&
    !settings.refTable &&
    !param.sum
  ) {
    var refRow = {};
    if (value && param.element)
      refRow = getData(
        settings.reference,
        value,
        null,
        settings.objectSub,
        param.element,
        function () {
          param.element.val(
            getObjectValue(data, [settings.reference, value, 'short'])
          );
        }
      );
    else if (value) refRow = getData(settings.reference, value);
    if (isEmptyObject(refRow)) return null;
    if (
      value &&
      param.table &&
      settings.reference == 'document' &&
      refRow.type == 'image'
    )
      value = '<img src="' + refRow.file + '">';
    // else if (value && param.table && settings.reference == 'document' && refRow.type == 'pdf' && mobile != 'smartphone') value = '<object data="' + refRow.file + '" type="application/pdf"></object>';
    else if (
      value &&
      param.table &&
      param.overview &&
      settings.reference == 'document' &&
      refRow.type == 'pdf'
    )
      value = '<canvas class="pdf" src="' + refRow.file + '"></canvas>';
    else if (value) {
      var shortKey = getParamSettings(settings, 'short', null, true, true);
      value = refRow[shortKey];
      if (!value || value == '0') value = '...';
    } else value = '';

    if (refRow && refRow[key + '_info']) value += ' - ' + refRow[key + '_info'];
    if (
      getObjectValue(fields, [settings.reference, 'avatar']) &&
      !param.noAvatar
    ) {
      var avatar = getData(
        settings.reference,
        param.value,
        'avatar',
        settings.objectSub
      );
      if (avatar && avatar.length > 50)
        value =
          '<div class="d-flex align-items-center"><span class="avatar avatar-sm me-2"><img src="' +
          avatar +
          '" class="img-fluid rounded-circle"></span>' +
          value +
          '</div>';
    }
    if (refRow.disabled) param.disabled = true;
  } else if (key == 'chat') {
    if (row.chat_unseen) value = '<i class="icon fa-solid fa-comment"></i>';
    else if (value && value.length)
      value = '<i class="icon fa-regular fa-comment"></i>';
    else value = '';
  }

  // json field
  else if (settings.json && read && (isArray(value) || isPlainObject(value))) {
    var values1 = [];
    if (isArray(value) && settings.edit && isPlainObject(settings.edit))
      value = value.length;
    else if (isArray(value)) {
      $.each(value, function (i, r) {
        var values2 = [];
        if (isPlainObject(r) && i < 2)
          $.each(r, function (k, v) {
            if (settings.singleKey) {
              if (k == settings.singleKey) values1.push(v);
            } else if (settings.fields) {
              var index = inArray(k, settings.fields) + 1;
              if (index == 1) index = '';
              v = convert({
                value: v,
                settings: {field: settings['field' + index]},
              });
              values2.push(v);
            }
          });
        if (values2.length) values1.push(values2.join(': '));
      });
      value = values1.join(', ');
    } else if (isPlainObject(value) && isset(value.value)) value = value.value;
  }

  // timestamp field
  else if (settings.timestamp && settings.format && value) {
    value = moment(value).format(settings.format);
  }

  // date field
  else if (
    settings.date &&
    value &&
    isString(value) &&
    !settings.reference &&
    !str_contains(value, '.')
  ) {
    var day = value.slice(0, 10);
    if (day == moment().format('YYYY-MM-DD') && !param.exact)
      value = info.time.today + (!row || !row.allDay ? value.slice(10) : '');
    else if (
      day == moment().subtract(1, 'days').format('YYYY-MM-DD') &&
      !param.exact
    )
      value =
        info.time.yesterday + (!row || !row.allDay ? value.slice(10) : '');
    else if (
      day == moment().add(1, 'days').format('YYYY-MM-DD') &&
      !param.exact
    )
      value = info.time.tomorrow + (!row || !row.allDay ? value.slice(10) : '');
    else if (
      settings.type == 'date' ||
      value.length == 10 ||
      (row && row.allDay)
    )
      value = moment(value).format('DD.MM.YYYY');
    else value = moment(value).format('DD.MM.YYYY HH:mm:ss');
  } else if (settings.month && value && read)
    value = moment(value).format('MM/YY');
  else if (settings.week && value && read) value = value.slice(-2);
  else if (
    (settings.field == 'checkbox' && settings.icon && param.table) ||
    settings.field == 'checkbutton'
  ) {
    if (value) value = addIcon(null, settings.icon);
    else if (settings.iconEmpty) value = addIcon(null, settings.iconEmpty);
    else value = '';
  }
  // else if (settings.link && settings.iconBtn && param.table && value) value = addIcon(null, settings.iconBtn);
  else if (settings.field == 'steps') {
    values = {};
    if (!isArray(value)) value = [value];
    $.each(value, function (i, val) {
      var color = 'success';
      if (str_contains(val, ':')) [val, color] = val.split(':');
      else if (settings.color && settings.color[val])
        color = settings.color[val];
      if (!values[val]) values[val] = color;
    });
    value = '';
    $.each(settings.values, function (i, val) {
      var color = 'light';
      if (values[val]) color = values[val];
      value +=
        '<span class="badge rounded-pill bg-' +
        color +
        ' me-1" tooltip="' +
        settings.labels[i] +
        '"></span>';
    });
  } else if (
    (settings.field == 'color' && read) ||
    (str_starts_with(key, 'warning') && param.table)
  )
    value = '<span class="badge rounded-pill bg-' + value + '"></span>';
  else if (
    settings.field == 'checkbox' &&
    !isArray(value) &&
    read &&
    !param.sum &&
    !settings.value
  ) {
    if (value) value = 'ja';
    else if (isset(value)) value = 'nein';
    else value = '';
  } else if (settings.labels && !settings.noConvert && !param.sum) {
    if (!isset(value)) value = '';
    else if (settings.number) value = value.toString();
    var labels = settings.labels;
    // if (settings.pdfValue) labels = settings.pdfValue;
    if (settings.values && settings.multi && labels && isArray(value)) {
      values = [];
      $.each(value, function (i, val) {
        values.push(labels[settings.values.indexOf(val)]);
      });
      value = values.join(', ');
    } else if (settings.multi && isArray(value)) value = value.join(', ');
    else if (labels && isPlainObject(labels) && labels[value])
      value = labels[value];
    else if (settings.values && labels && !isPlainObject(labels)) {
      if (labels[settings.values.indexOf(value)])
        value = labels[settings.values.indexOf(value)];
      else value = '';
    } else if (value == '0') value = '';
    if (str_contains(value, '__'))
      value = convertPattern({
        text: value,
        row: row,
        object: param.object,
        readValue: param.key,
        pdfValue: true,
      });
  } else if (
    (settings.number || param.sum) &&
    !settings.value &&
    !settings.reference &&
    read
  ) {
    if (!value && settings.entity) value = 0;
    else if (settings.decimal && !settings.noRound) value = round(value, 2);
    var dig = 0;
    if (settings.decimal && value && isNumber(value))
      dig = countDecimals(value);
    if (settings.decimal && dig < 2) dig = 2;
    var sep = '.';
    if (settings.type && !settings.entity) sep = '';
    value = number_format(value, dig, ',', sep);
  } else if (settings.decimal && value && settings.noRound)
    value = value.toString().replace('.', ',');
  else if (settings.decimal) {
    value = round(value, 2);
    if (value && isNumeric(value)) value = number_format(value, 2, ',', '.');
  } else if (settings.multi && read && isArray(value)) value = value.join(', ');
  // else if (settings.field == 'password' && value && !read) value = '';
  else if (
    (settings.field == 'textarea' || settings.textarea) &&
    value &&
    read &&
    !param.exact
  )
    value = nl2br(value);
  else if (
    settings.values &&
    settings.labels &&
    read &&
    inArray(value, settings.values)
  ) {
    if (isPlainObject(settings.labels) && settings.labels[value])
      value = settings.labels[value];
    else {
      var text = settings.labels[settings.values.indexOf(value)];
      if (settings.field == 'input') value += ' ' + text;
      else value = text;
    }
  }

  if (
    (key == 'short' || isset(shortKey)) &&
    isString(value) &&
    value.match(/(^| )check( |$)/)
  )
    value = value.replace('check', '<i class="icon fa-regular fa-check"></i>');
  if (settings.spots && value && isNumeric(value))
    value = str_pad(value, settings.spots);
  if (
    settings.value &&
    isString(settings.value) &&
    read &&
    !isEmptyObject(row) &&
    param.readValue != param.key
  ) {
    if (!str_contains(settings.value, '__') && value) value = settings.value;
    // else if (str_contains(settings.value, '__') && (isset(value) || !str_contains(settings.value, '__' + key + '__')))
    else if (str_contains(settings.value, '__') && (value || !settings.type))
      value = convertPattern({
        text: settings.value,
        row: row,
        object: param.object,
        readValue: param.key,
        pdfValue: true,
        key: key,
      });
    else value = '';
    if (value && str_starts_with(value, 'fa-'))
      value = '<i class="fa ' + value + '"></i>';
  }
  if (settings.entity && param.entity)
    value =
      calculate(settings.entity, null, row, {
        object: object,
        currency: true,
      }) +
      ' ' +
      value;
  if (value === null) value = '';
  // if ((settings.reference || settings.func == 'no') && (!value || value === '0') && read) value = '';

  return value;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function checkObjectSub2(param) {
  // check if data suits sub object
  var subs = [];
  var subsOrder = [];
  var row = data[param.object][param.id];
  if (!row) row = param.row;
  if (!row) return;

  $.each(parameter[param.object], function (option, value) {
    if (
      option != 'where' &&
      option.slice(0, 5) == 'where' &&
      checkWhere(row, value, {object: param.object})
    ) {
      var sub = option.slice(5, 1).toLowerCase() + option.slice(6);
      subs.push(sub);
      subsOrder.push(Object.keys(value).length);
    }
  });
  subs.sort(function (a, b) {
    return strpos(subsOrder, a) - strpos(subsOrder, b);
  });
  subs.reverse();
  if (subs[0]) param.objectSub = subs[0];
}
